<template>
	<div id="main-wrapper" class="inner-pages tradcond-page">
		<div class="section banner-holder">

			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Trading Conditions</h2>
				<p>Features that will make you trade more profitably! A trading environment best suited for all kinds of trading strategies. </p>
			</div>
		</div>
		<div class="section tradcond-content">
			<div class="wrapper">
				<div class="title-holder t-left wow fadeIn">
					<h5>Take a look at all the terms and conditions to plan for an impeccable trading experience:</h5>

					<p>AAAFx presents trading terms and conditions suitable for every trader - Be it a beginner or an expert. We want our clients to trade with utmost confidence and comfort. </p>
				</div>
				<div class="listing-cond">
					<div class="content-sec wow fadeIn">
						<div class="text-holder">
							<h3>ECN Trading with Deep liquidity</h3>
							<p>ECN (Electronic Communication Network) accounts are designed to match buy and sell orders through direct access to liquidity providers.
							<br><br>
							Through our Prime LPs, we are able to pass on the best prevailing market prices and liquidity to all our clients in a seamless way.
							Hence you get access to the deep liquidity pools instantly at a negligible spread cost.
							</p>
						</div>
						<div class="img-holder">
							<img src="assets/images/cond1.webp" alt="AAAFx" title="AAAFx" width="455" height="205" class="fluid-img" />
						</div>
					</div>
					<div class="content-sec wow fadeIn">
						<div class="img-holder">
							<img src="assets/images/cond2.webp" alt="AAAFx" title="AAAFx" width="410" height="184" class="fluid-img" />
						</div>
						<div class="text-holder">
							<h3>AAAFx Powerful Trading Arsenal </h3>
							<p>We know how crucial it is to choose a quintessential trading platform to implement your trading strategy! We have taken care of all your requirements to trade swiftly with easy navigation and user interface and earn from over 200+ trading instruments available on three full-packed trading platforms - MT4, MT5, ActTrader. The icing on the cake is connection to Zulu Trade via Zulu Account, that will help you get instantly connected to the largest social trading community.
							</p>
						</div>
					</div>
					<div class="content-sec">
						<div class="text-holder">
							<h3>Zero commission and Razor thin Spreads</h3>
							<p>We provide you the best conditions to trade at the lowest cost. With our ECN plus account, you can trade free of commission charges on all Forex pairs. With ultra-thin spreads starting from 0.0 pips on all our tradable instruments, you can start using our institutional grade liquidity at a super-low cost. We get these trading conditions from our top-tier liquidity providers to serve you the best in your trading journey.
							</p>
						</div>
						<div class="img-holder">
							<img src="assets/images/cond3.webp" alt="AAAFx" title="AAAFx" width="376" height="99" class="fluid-img" />
						</div>
					</div>
					<div class="content-sec">
						<div class="img-holder">
							<img src="assets/images/cond4.webp" alt="AAAFx" title="AAAFx" width="176" height="182" class="fluid-img" />
						</div>
						<div class="text-holder">
							<h3>Lightning-fast execution</h3>
							<p>The top-notch quality and location of our severs enables the fastest trade execution possible at a speed as fast as 10ms. You can place trade orders of upto 100 lots. You are free from delays, requotes and dealing desks. This makes our platform ideal for all kinds of traders - scalpers, beginners, EA users, manual traders, etc.
							</p>
						</div>
					</div>
					<div class="content-sec">
						<div class="img-holder">
							<img src="assets/images/cond6.webp" alt="AAAFx" title="AAAFx" width="416" height="166" class="fluid-img" />
						</div>
						<div class="text-holder">
							<h3>Variety of Funding Options </h3>
							<p>Seamless deposits and withdrawals go a long way in making your trading journey easy and smooth. You can choose your deposit method from diverse options available. We know every penny counts in funding your trading accounts. Thus, we do not charge any fees while accepting deposits or processing withdrawals. Withdrawals are processed ultra-fast in a seamless manner, and you get a variety of withdrawal modes to choose from.
							</p>
						</div>
					</div>
					
					<div class="content-sec charges-sec">
						<div class="text-holder">
							<h3>Low Rollover charges </h3>
							<p>Several traders like to hold their trades overnight and for that purpose, swap rates matter. Each trading pair has its own swap charges (except on Islamic accounts). These rates are applied at 00:00 Server time. The markets are closed over the weekend; however, Banks still calculate interest even on positions held over the weekend. So, if you hold a position overnight on a Friday, you will incur 3X swap rates. We offer competitive and low swap charges so that you have more flexibility to decide when you want to close your trades.
							</p>
						</div>
						<div class="img-holder">
							<img src="assets/images/cond7.webp" alt="AAAFx" title="AAAFx" width="137" height="137" class="fluid-img" />
						</div>
					</div>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>Have doubts over any trading condition? Try our <a :href="siteurl2" target="_blank" >Demo account</a> <br>
						Stay aware and make use of extensive <router-link to="/trading-calculator">Trading Calculators</router-link> to get all the numbers right!
					</p>
					<div class="btn-holder t-center wow fadeIn">
						<a :href="siteurl2" target="_blank" class="btn btn-yellow">OPEN LIVE ACCOUNT</a>
						<a :href="siteurl2" target="_blank" class="btn btn-blue">OPEN DEMO ACCOUNT</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
       siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL, 
    };
  },
  
};
</script>